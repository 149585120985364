
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { Lead } from '@/entities/crm'

@Component
  export default class AgendaTitle extends Vue {
  @Prop({ type: Object, default: null }) agenda!: { date: string, title: string, allowSelect: boolean, lead: Lead }
  @Prop({ type: String, default: '' }) date!: string
  @Prop({ type: String, default: '' }) currentTime!: string
  }
